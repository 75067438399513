import { useState } from "react";
import { Link } from "react-router-dom";
import "./EquipmentTable.css";


const EquipmentTable = ({ equipments, onDelete }) => {
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const handleClick = (column) => {
    if (column === sortBy){
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    }
    else {
      setSortBy(column);
      setSortDirection('asc');
    }
  }

  const sortEquipments = (column, order ='asc') => {return (a, b) => {
    if (!a.hasOwnProperty(column) || !b.hasOwnProperty(column)){
      return 0
    }

    const valueA = typeof a[column]==='string' ? a[column].toUpperCase() : a[column];
    const valueB = typeof b[column]==='string' ? b[column].toUpperCase() : b[column];

    let comparsion = 0
    if (valueA > valueB) comparsion = 1;
    else if (valueA < valueB) comparsion = -1

    return order==='desc' ? comparsion *-1 : comparsion

  }}

  const sortedEquipments = equipments && equipments.sort(sortEquipments(sortBy, sortDirection));

  return(
    <div className="EquipmentTable">
      <table>
        <thead>
          <tr>
            <th onClick={()=>handleClick('name')}>Name</th>
            <th onClick={()=>handleClick('type')}>Type</th>
            <th onClick={()=>handleClick('amount')}>Amount</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {sortedEquipments && sortedEquipments.map((equipment) => (
            <tr key={equipment._id}>
              <td>{equipment.name}</td>
              <td>{equipment.type}</td>
              <td>{equipment.amount}</td>
              <td>
                <Link to={`/update-equipment/${equipment._id}`}>
                  <button type="button">Update</button>
                </Link>
                <button type="button" onClick={() => onDelete(equipment._id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )};

export default EquipmentTable;
