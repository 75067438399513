import { useState } from "react";
import { Link } from "react-router-dom";
import "./CarriageTable.css";


const EmployeeTable = ({ carriages, onDelete }) => {
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const handleClick = (column) => {
    if (column === sortBy){
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    }
    else {
      setSortBy(column);
      setSortDirection('asc');
    }
  }

  const sortCarriages = (column, order ='asc') => {return (a, b) => {
    if (!a.hasOwnProperty(column) || !b.hasOwnProperty(column)){
      return 0
    }

    const valueA = typeof a[column]==='string' ? a[column].toUpperCase() : a[column];
    const valueB = typeof b[column]==='string' ? b[column].toUpperCase() : b[column];

    let comparsion = 0
    if (valueA > valueB) comparsion = 1;
    else if (valueA < valueB) comparsion = -1

    return order==='desc' ? comparsion *-1 : comparsion

  }}

  const sortedCarriages = carriages.sort(sortCarriages(sortBy, sortDirection));

  return(
    <div className="CarriageTable">
      {/* {console.log(`sort by column ${sortBy} direction ${sortDirection}`)} */}
      <p>To jest komponent CarriageTable</p>
      <table>
        <thead>
          <tr>
            <th onClick={()=>handleClick('NR_WAGONU')}>Nr Wagonu</th>
            <th onClick={()=>handleClick('PRODUKT')}>Produkt</th>
            <th onClick={()=>handleClick('NR_PARTII')}>Nr Partii</th>
            <th onClick={()=>handleClick('SPEDYTOR')}>Spedytor</th>
            <th onClick={()=>handleClick('TERMINAL_PLANOWY')}>Terminal Planowy</th>
            <th onClick={()=>handleClick('TERMINAL_OSTATECZNY')}>Terminal Ostateczny</th>
            <th onClick={()=>handleClick('NR_POCIAGU_UA')}>Nr Pociągu UA</th>
            <th onClick={()=>handleClick('NR_POCIAGU_PL')}>Nr Pociągu PL</th>
            <th onClick={()=>handleClick('NAJEMCA')}>Najemca</th>
            <th onClick={()=>handleClick('NR_WAGONU')}>Nr Wagonu</th>
            <th onClick={()=>handleClick('PRODUKT')}>Produkt</th>
            <th onClick={()=>handleClick('NR_PARTII')}>Nr Partii</th>
            <th onClick={()=>handleClick('SPEDYTOR')}>Spedytor</th>
            <th onClick={()=>handleClick('TERMINAL_PLANOWY')}>Terminal Planowy</th>
            <th onClick={()=>handleClick('TERMINAL_OSTATECZNY')}>Terminal Ostateczny</th>
            <th onClick={()=>handleClick('NR_POCIAGU_UA')}>Nr Pociągu UA</th>
            <th onClick={()=>handleClick('NR_POCIAGU_PL')}>Nr Pociągu PL</th>
            <th onClick={()=>handleClick('NAJEMCA')}>Najemca</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {sortedCarriages.map((carriage) => (
            <tr key={carriage.ID_WAGON}>
              <td>{carriage.NR_WAGONU}</td>
              <td>{carriage.PRODUKT}</td>
              <td>{carriage.NR_PARTII}</td>
              <td>{carriage.SPEDYTOR}</td>
              <td>{carriage.TERMINAL_PLANOWY}</td>
              <td>{carriage.TERMINAL_OSTATECZNY}</td>
              <td>{carriage.NR_POCIAGU_UA}</td>
              <td>{carriage.NR_POCIAGU_PL}</td>
              <td>{carriage.NAJEMCA}</td>
              <td>{carriage.NR_WAGONU}</td>
              <td>{carriage.PRODUKT}</td>
              <td>{carriage.NR_PARTII}</td>
              <td>{carriage.SPEDYTOR}</td>
              <td>{carriage.TERMINAL_PLANOWY}</td>
              <td>{carriage.TERMINAL_OSTATECZNY}</td>
              <td>{carriage.NR_POCIAGU_UA}</td>
              <td>{carriage.NR_POCIAGU_PL}</td>
              <td>{carriage.NAJEMCA}</td>
              <td>
                <Link to={`/update/${carriage._id}`}>
                  <button type="button">Update</button>
                </Link>
                <button type="button" onClick={() => onDelete(carriage._id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )};

export default EmployeeTable;
