import React, { createContext, useContext, useState } from "react";

const LayoutContext = createContext();

export const useLayoutContext = () => {
  return useContext(LayoutContext);
};

export const LayoutProvider = ({ children }) => {
    const [layoutProps, setLayoutProps] = useState({selectedLevel:'', selectedPosition:'', searching:''});

    const updateLayoutProps = (newProps) => {
        setLayoutProps((prevProps) => ({ ...prevProps, ...newProps }));
      };

  return (
    <LayoutContext.Provider value={{layoutProps, updateLayoutProps }}>
      {children}
    </LayoutContext.Provider>
  );
};