import { Outlet, Link } from "react-router-dom";
import devilsTriangle from '../../Img/devilsTriangle.png'
import logo from '../../Img/CL-logo.png';
import point from '../../Img/carriage.png';
import config from '../../Img/config_1.png';
import user from '../../Img/user.png';
import edit from '../../Img/edit.png';
import logout from '../../Img/log-out.png';
import settings from '../../Img/settings.png';
import React, { useState, useEffect, useRef } from 'react';
import { useLayoutContext } from "./LayoutContext";
import "./Layout.css";

const Layout = () => {

  const { updateLayoutProps } = useLayoutContext();
  const [open, setOpen] = useState(false);
  let menuRef = useRef();
  
  useEffect(() => {
    let handler = (e)=>{
      if(!menuRef.current.contains(e.target)){
        setOpen(false);
        console.log(menuRef.current);
      }      
    };

    document.addEventListener("mousedown", handler);
    return() =>{
      document.removeEventListener("mousedown", handler);
    }

  });

  return (
    <div className="Layout">
      <nav>
        <Link to="/">Title
          {/* <img src={logo} alt="logo" className="logo"></img> */}
        </Link>
        <ul className="line">
          <li className="link">
            <Link to="/">Menu 1</Link>
          </li>
          <li className="link">
            <Link to="/equipments">Menu 2</Link>
          </li>
          <li className="link">
            <Link to="/">Menu 3</Link>
          </li>
        </ul>

        <div className="dropdown" ref={menuRef}>
          <div className="dropdown-trigger" onClick={()=>{setOpen(!open)}}>
            <img src={config} alt="config" className="config"></img>
          </div>
          <div className={`dropdown-menu ${open? 'active' : 'inactive'}`}>
            <h3>The ADSM<br/><span>Website Designer</span></h3>
            <ul>
              <DropdownItem img={point} alt={"point"} linkURL={"/"} linkName={"Parametry"} />
              <DropdownItem img={edit} alt={"edit"} linkURL={"/edit"} linkName={"Edit"} />
              <DropdownItem img={settings} alt={"settings"} linkURL={"/settings"} linkName={"Settings"} />
              <DropdownItem img={logout} alt={"logout"} linkURL={"/logout"} linkName={"Logout"} />
            </ul>
          </div>
        </div>
      </nav>
      <div className="OutletWrapper">
        Reszta
        <Outlet />
      </div>
    </div>
  )
};

const DropdownItem = ({ img, alt, linkURL, linkName }) =>{
  return(
    <li className="dropdownItem">
      <img src={img} alt={alt}/>
      <Link to={linkURL}>{linkName}</Link>
    </li>
  );
}
export default Layout;
