import { useEffect, useState } from "react";
import Loading from "../Components/Loading";
import CarriageTable from "../Components/CarriageTable";
import {useLayoutContext } from "./Layout/LayoutContext";


const fetchCarriages = () => {
  return fetch("https://api.adsm-si.pl/api/carriages").then((res) => res.json());
};

const deleteCarriage = (id) => {
  return fetch(`/api/carriages/${id}`, { method: "DELETE" }).then((res) =>
    res.json()
  );
};

const CarriageList = () => {
  const [loading, setLoading] = useState(true);
  const [carriage, setCarraige] = useState(null);
  const layoutProps = useLayoutContext();

  const filteredCarriages = carriage && carriage.filter((carriages)=>{
      if (layoutProps.layoutProps.selectedLevel === '' &&
          layoutProps.layoutProps.selectedPosition === '') {
        return true;
      } 
      else if (layoutProps.layoutProps.selectedLevel != '' &&
               layoutProps.layoutProps.selectedPosition === '') {
        return carriages.level === layoutProps.layoutProps.selectedLevel;
      }
      else if (layoutProps.layoutProps.selectedLevel === '' &&
               layoutProps.layoutProps.selectedPosition != '') {
        return carriages.position === layoutProps.layoutProps.selectedPosition;
       }
       else {
        return carriages.position === layoutProps.layoutProps.selectedPosition &&
               carriages.level === layoutProps.layoutProps.selectedLevel;
      }
    })


  const handleDelete = (id) => {
    deleteCarriage(id);

    setCarraige((carriages) => {
      return carriages.filter((carriage) => carriage._id !== id);
    });
  };

  useEffect(() => {
    fetchCarriages()
      .then((carriages) => {
        setLoading(false);
        setCarraige(carriages);
      })
  }, []);

  if (loading) {
    return <Loading />;
  }

  return <CarriageTable carriages={filteredCarriages} onDelete={handleDelete} />;
};

export default CarriageList;

